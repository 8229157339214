/* Mobile styles */
@media (max-width: 767px) {
  .App-header {
    background-color: #282c34; /* Same as desktop */
    display: flex; /* Same as desktop */
    flex-direction: column; /* Same as desktop */
    align-items: center; /* Same as desktop */
    justify-content: center; /* Same as desktop */
    font-size: 12px; /* Smaller font size for mobile devices */
    color: white; /* Same as desktop */
  }

  .bottone1 {
    margin-top: 10px; /* Smaller margin for mobile devices */
    padding-left: 15px; /* Smaller padding for mobile devices */
    padding-right: 15px; /* Smaller padding for mobile devices */
    padding-bottom: 8px; /* Smaller padding for mobile devices */
    padding-top: 8px; /* Smaller padding for mobile devices */
    border-radius: 4px; /* Smaller border radius for mobile devices */
    background: #d5f365; /* Same as desktop */
    border: none; /* Same as desktop */
    font-family: inherit; /* Same as desktop */
    text-align: center; /* Same as desktop */
    cursor: pointer; /* Same as desktop */
    transition: 0.4s; /* Same as desktop */
  }

  .bottone1:hover {
    box-shadow: 3px 2px 28px -7px #c3d900; /* Smaller shadow for mobile devices */
  }

  .bottone1:active {
    transform: scale(0.97); /* Same as desktop */
    box-shadow: 3px 2px 28px -5px #c3d900; /* Smaller shadow for mobile devices */
  }

  .btn-no {
    appearance: none;
    background-color: #fafbfc;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 4px; /* Smaller border radius for mobile devices */
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292e;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 12px; /* Smaller font size for mobile devices */
    font-weight: 500;
    line-height: 18px; /* Smaller line height for mobile devices */
    list-style: none;
    padding: 5px 12px; /* Smaller padding for mobile devices */
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
  }

  .btn-no:hover {
    background-color: #f3f4f6;
    text-decoration: none;
    transition-duration: 0.1s;
  }

  .btn-no:disabled {
    background-color: #fafbfc;
    border-color: rgba(27, 31, 35, 0.15);
    color: #959da5;
    cursor: default;
  }

  .btn-no:active {
    background-color: #edeff2;
    box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
    transition: none 0s;
  }

  .btn-no:focus {
    outline: 1px transparent;
  }

  .btn-no:before {
    display: none;
  }

  .btn-no:-webkit-details-marker {
    display: none;
  }
}

/* Desktop styles */
@media (min-width: 992px) {
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .bottone1 {
    margin-top: 20px;
    padding-left: 33px;
    padding-right: 33px;
    padding-bottom: 16px;
    padding-top: 16px;
    border-radius: 9px;
    background: #d5f365;
    border: none;
    font-family: inherit;
    text-align: center;
    cursor: pointer;
    transition: 0.4s;
  }

  .bottone1:hover {
    box-shadow: 7px 5px 56px -14px #c3d900;
  }

  .bottone1:active {
    transform: scale(0.97);
    box-shadow: 7px 5px 56px -10px #c3d900;
  }

  .btn-no {
    appearance: none;
    background-color: #fafbfc;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292e;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    padding: 6px 16px;
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
  }

  .btn-no:hover {
    background-color: #f3f4f6;
    text-decoration: none;
    transition-duration: 0.1s;
  }

  .btn-no:disabled {
    background-color: #fafbfc;
    border-color: rgba(27, 31, 35, 0.15);
    color: #959da5;
    cursor: default;
  }

  .btn-no:active {
    background-color: #edeff2;
    box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
    transition: none 0s;
  }

  .btn-no:focus {
    outline: 1px transparent;
  }

  .btn-no:before {
    display: none;
  }

  .btn-no:-webkit-details-marker {
    display: none;
  }
}
